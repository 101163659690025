export default function getLocaleUrlFromAssetReference(
  assetReference = ''
): string {
  if (!assetReference) return '';

  const fullUrl = window.Shell.importmap.imports[assetReference];
  if (!fullUrl) return '';

  const baseUrl = fullUrl.split('/').slice(0, -1).join('/') + '/';
  const localePath = 'locale/';

  const fileName =
    window.Shell.v1.localization.locale.replace('-', '_') + '.json';

  return baseUrl + localePath + fileName;
}
