import { NavigationType } from '@jarvis/shell-commons/dist/interface/v1/orgSelector/types';

// FIXME: Replace it for a better solution
export function registerPostOnboardingForcedPageReload(
  onboardingPath: string,
  navigation: NavigationType
) {
  let postOnboardingPageReload = false;
  navigation.listen(onboardingPathChangeHandler);
  navigation.block(postOnboardingBlockerPushStateHandler as any);

  function isOnboardingPath(location: NavigationType['location']) {
    return location.pathname === onboardingPath;
  }

  function onboardingPathChangeHandler(location: NavigationType['location']) {
    postOnboardingPageReload = isOnboardingPath(location);
  }

  function postOnboardingBlockerPushStateHandler(
    location: NavigationType['location']
  ) {
    const allowNavigation = true;
    // Should reload AND isn't in onboarding path anymore
    if (postOnboardingPageReload && !isOnboardingPath(location)) {
      console.debug('Leaving user onboarding page. Will reload the page.');

      //@ts-expect-error Update the Commons types when available.
      navigation.redirect(location.pathname);
    }
    return allowNavigation;
  }

  onboardingPathChangeHandler(navigation.location);
}
