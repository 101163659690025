import getLocaleUrlFromAssetReference from './getLocaleUrlFromAssetReference';

export function systemImportWithLocale(assetReference: string): Promise<any> {
  const promiseRequested = System.import(assetReference);

  const UrlLocale = getLocaleUrlFromAssetReference(assetReference);
  if (UrlLocale)
    window.Shell?.v1?.localization?.prefetchLocaleFile?.(UrlLocale);

  return promiseRequested;
}
