import { ImportmapType } from '../types/shell';

export function setImportMapOnWindow(importmap: ImportmapType): void {
  if (!window.Shell) {
    //@ts-ignore
    window.Shell = {};
  }
  window.Shell.importmap = importmap;
}

export async function getImportMap(): Promise<ImportmapType> {
  try {
    const getImportmapByElementId = async (
      id: string
    ): Promise<ImportmapType> => {
      try {
        const importmapHTMLElement = document.getElementById(
          id
        ) as HTMLScriptElement;
        if (importmapHTMLElement?.innerHTML) {
          return JSON.parse(importmapHTMLElement?.innerHTML);
        } else {
          const src = importmapHTMLElement?.src;
          if (src) {
            return fetch(importmapHTMLElement?.src).then((response) =>
              response.json()
            );
          }
        }
      } catch (error) {
        console.error(`Failed to get importmap value from id: ${id}. `, error);
        return undefined;
      }
    };

    // The first importmap will be overriden by the second importmap
    const importmapIdOrder = ['shell-importmap', 'shell-importmap-override'];

    if (process.env.isLocal) {
      importmapIdOrder.unshift('shell-default-dependencies-importmap');
    }

    const importmap: ImportmapType = {
      imports: {}
    };

    for (const id of importmapIdOrder) {
      const thisImportmap = await getImportmapByElementId(id);

      if (thisImportmap?.imports) {
        importmap.imports = {
          ...importmap.imports,
          ...thisImportmap?.imports
        };
      }
    }

    return importmap;
  } catch (error) {
    console.error('Failed to get importmap value: ', error);
  }
}
