import { ShowErrorPagePropsType } from './types';

const getNode = (id: string) => document.getElementById(id);

type HTMLElementType = HTMLDivElement & {
  replaceChildren(...nodes: (Node | string)[]): void;
};

const applicationStatusManagerConstants = {
  nodes: {
    error: {
      node: getNode('root-config-error-wrapper'),
      childrens: {
        title: {
          node: getNode('root-config-error-title'),
          label: 'Something went wrong.'
        },
        message: {
          node: getNode('root-config-error-message'),
          label: 'Please, click on the button below or reload the page.'
        },
        buttons: {
          node: getNode('root-config-error-buttons-wrapper') as HTMLElementType,
          list: [
            {
              label: 'Reload',
              onClick: () => window.location.reload()
            }
          ] as ShowErrorPagePropsType['buttons']['list']
        }
      }
    }
  },
  classes: {
    hidden: 'hidden'
  }
};

export default applicationStatusManagerConstants;
