import { registerApplication } from 'single-spa';
import idleLogoutClosure from './idleLogoutClosure';
import { services, utils } from '@jarvis/shell-commons';
import * as T from './types';
import applicationStatusManager from '../../services/applicationStatusManager';
import { systemImportWithLocale } from '../../utils/systemImportWithLocale';

let initialized = false;

export default async function registerApplications(
  initializerState: T.registerApplicationsPropsType
): Promise<void> {
  if (initialized) {
    throw new Error('registerApplications was already called');
  }
  initialized = true;

  const { manifest, interfaces } = initializerState;
  const { store, navigation, sessionInterface } = interfaces?.v1 || {};
  const { login } = manifest?.services || {};
  const loginAssetReference =
    login?.assetReference || '@jarvis/react-shell-login';
  const isLoginEnabled = login?.enable !== false;
  const shouldInitializeLogin = isLoginEnabled && !!loginAssetReference;
  const isLoggedIn = !!sessionInterface?.isLoggedIn?.();
  const stack = utils.stackValidator(manifest?.portal?.stack);
  const tenantSelectorAssetReference = '@jarvis/react-shell-tenant-selector';

  const getCurrentRoute = () =>
    utils.getCurrentRoute({
      routes: manifest?.navigation?.routes,
      navigation
    });

  const customProps = {
    mosaicProps: manifest,
    session: services.session,
    stack: stack,
    history: navigation,
    navigateFunction: navigation.push,
    getCurrentRoute,
    applicationStatusManager
  };

  document.title = manifest?.portal?.appName;

  store.setState({
    manifest,
    session: { isLoggedIn }
  });

  function isLoginPath() {
    const loginPaths = [];
    const _isLoginPath = loginPaths.some(
      (e) => navigation.createHref({ pathname: e }) === window.location.pathname
    );

    return _isLoginPath;
  }

  function isLoginActive() {
    if (!shouldInitializeLogin) return false;

    const _isLoginPath = isLoginPath();
    if (_isLoginPath || !isLoggedIn) {
      idleLogoutClosure.stopIdleTimer();
    } else {
      idleLogoutClosure.startIdleTimer(manifest);
    }

    return _isLoginPath;
  }

  registerApplication({
    name: '@jarvis/shell-nav',
    app: () => {
      return systemImportWithLocale('@jarvis/shell-nav');
    },
    activeWhen: () => !isLoginActive(),
    customProps: {
      ...customProps,
      // TODO: This won't be necessary anymore.
      tenantSelector: tenantSelectorAssetReference
    }
  });
}
