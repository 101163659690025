import { ImportmapType } from '../../types/shell';

type PostMessageType = typeof navigator.serviceWorker.controller.postMessage;
const SECOND_IN_MILISECONDS_CONSTANT = 1000;

const messageTypeNames = {
  appLaunching: 'APP-LAUNCHING',
  clearCache: 'CLEAR-CACHE',
  routeChanged: 'ROUTE-CHANGED'
};

let createdWebManifestScript = false;
async function enablePWA() {
  if (createdWebManifestScript) return;

  createdWebManifestScript = true;
  const link = document.createElement('link');
  link.rel = 'manifest';
  link.href = '/webapp.webmanifest';
  document.head.appendChild(link);
}

type RegisterServiceWorkersProps = {
  manifest: Record<string, any>;
  importmap: ImportmapType;
};

let started = false;
async function registerServiceWorkers({
  manifest,
  importmap
}: RegisterServiceWorkersProps): Promise<void> {
  if (started) return;
  started = true;

  if (!navigator?.serviceWorker?.register) {
    console.warn('Service worker is not supported on this browser');
    return;
  } else {
    try {
      if (manifest?.services?.serviceWorker?.pwa?.enable) {
        enablePWA();
      }
      const registration = await navigator?.serviceWorker?.register?.(
        '/serviceworker.js'
      );

      const postMessage: PostMessageType = (message, options) => {
        if (navigator?.serviceWorker?.controller?.postMessage) {
          navigator?.serviceWorker?.controller?.postMessage?.(message, options);
        } else if (registration?.active?.postMessage) {
          registration?.active?.postMessage?.(message, options);
        } else if (registration?.waiting?.postMessage) {
          registration?.waiting?.postMessage?.(message, options);
        } else if (registration?.installing?.postMessage) {
          registration?.installing?.postMessage?.(message, options);
        }
      };

      postMessage({
        data: { manifest, importmap },
        type: messageTypeNames.appLaunching
      });
    } catch (err) {
      console.error('Error registering Service Worker:', err);
    }
  }
}

async function clearCaches() {
  const cacheStorages = await caches?.keys?.();

  cacheStorages.map((cacheStorage) => {
    return caches?.delete?.(cacheStorage);
  });
}

async function unRegisterServiceWorkers() {
  const registrations = await navigator?.serviceWorker?.getRegistrations?.();

  await clearCaches();

  const unregisterPromiseList = registrations?.map?.((registration) => {
    registration?.unregister?.();
  });

  if (
    Array.isArray(unregisterPromiseList) &&
    unregisterPromiseList?.length > 0
  ) {
    await Promise.all(unregisterPromiseList);
  }
}

async function _getManifestFromWindowObjectWhenIsSetted({
  secondsToTimeOut = 2,
  timesToRetry = 10
}) {
  const manifest: any = await new Promise((resolve) => {
    let timer = 0;
    let isPromiseResolved = false;
    const timeOut = secondsToTimeOut * SECOND_IN_MILISECONDS_CONSTANT;
    const timeInterval = timeOut / timesToRetry;

    const resolvePromise = () => {
      const isTimedOut = timer > timeOut;
      if (
        !isPromiseResolved &&
        ((window as any)?.Shell?.manifest || isTimedOut)
      ) {
        resolve((window as any)?.Shell?.manifest);
        isPromiseResolved = true;
        return isPromiseResolved;
      }
      return isPromiseResolved;
    };

    if (!resolvePromise()) {
      const manifestIntervalCheck = setInterval(() => {
        timer += timeInterval;
        if (resolvePromise()) {
          clearInterval(manifestIntervalCheck);
        }
      }, timeInterval);
    }
  });
  return manifest;
}

export async function startServiceWorkers(): Promise<void> {
  try {
    const manifest = await _getManifestFromWindowObjectWhenIsSetted({
      secondsToTimeOut: 2,
      timesToRetry: 10
    });
    const importmap = window.Shell?.importmap;

    if (!manifest || !importmap) return;

    const manifestServiceWorker = manifest?.services?.serviceWorker;

    if (manifestServiceWorker?.enable) {
      await registerServiceWorkers({ manifest, importmap });
    } else {
      await unRegisterServiceWorkers();
    }
  } catch (error) {
    console.error('Failed to launch service worker: ', error);
  }
}
